import CronJob from '@shell/models/batch.cronjob';
import { getDetailLocation } from '../utils';
import { clone } from '@shell/utils/object';
import { POD, WORKLOAD_TYPES } from '@shell/config/types';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { matches } from '@shell/utils/selector';
import ContainerTypeMixin from '~/pkg/pai/mixins/containerType';

export default class extends ContainerTypeMixin(PaiLocationMixin(CronJob)) {
  async runNow() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      const job = await this.$dispatch('create', clone(this.spec.jobTemplate));

      job.type = WORKLOAD_TYPES.JOB;
      job.metadata = job.metadata || {};
      job.metadata.namespace = this.metadata.namespace;
      job.metadata.generateName = `${ this.metadata.name }-`;

      await job.save();

      this.currentRouter().push(getDetailLocation(this));
    } else {
      await super.runNow();
    }
  }

  get pods() {
    const relationships = this.metadata?.relationships || [];
    const podRelationship = relationships.filter(relationship => relationship.toType === POD)[0];

    if (podRelationship) {
      const pods = this.$rootGetters['cluster/podsByNamespace'](this.metadata.namespace);

      return pods.filter((obj) => {
        return matches(obj, podRelationship.selector);
      });
    } else {
      return [];
    }
  }
}
