<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
export default {
  components: { LabeledInput, LabeledSelect },
  props:      {
    value: {
      type:     Object,
      required: true,
    }
  },
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.tenants[0].name"
          :label="t('catalog.charts.minio.tenantsName')"
        />
      </div>
      <div class="col span-6">
        <LabeledSelect
          v-model="value.tenants[0].secrets.enabled"
          :label="t('catalog.charts.minio.tenantsSecrets')"
          :options="[{label:'true', value: true}, {label: 'false', value: false}]"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.tenants[0].secrets.name"
          :label="t('catalog.charts.minio.tenantsSecretsName')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.tenants[0].secrets.accessKey"
          :label="t('catalog.charts.minio.tenantsSecretsAccessKey')"
          type="password"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.tenants[0].secrets.secretKey"
          :label="t('catalog.charts.minio.tenantsSecretsSecretKey')"
        />
      </div>
      <div class="col span-6">
        <LabeledSelect
          v-model="value.tenants[0].metrics.enabled"
          :label="t('catalog.charts.minio.tenantsMetrics')"
          :options="[{label:'true', value: true}, {label: 'false', value: false}]"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.tenants[0].metrics.port"
          :label="t('catalog.charts.minio.tenantsMetricsPort')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
