<script>
export default {
  props: {
    row: {
      type:     Object,
      required: true,
    },
  },

  computed: {
    num() {
      if (this.row?.status?.rtinfo?.rtcontainers) {
        return Object.keys(this.row?.status?.rtinfo?.rtcontainers).length;
      }

      return '-';
    },
  },

};
</script>

<template>
  <div style="width: 40px">
    {{ num }}
  </div>
</template>
