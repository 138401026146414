<script>
export default {
  props: {
    row: {
      type:     Object,
      required: true,
    }
  },

  computed: {
    icon() {
      if (this.row.podType) {
        return require(`../assets/images/container/${ this.row.podType }.svg`);
      } else if (this.row.customType) {
        return require(`../assets/images/container/${ this.row.customType }.svg`);
      }

      return require(`../assets/images/container/default.svg`);
    },
  },

};
</script>

<template>
  <img
    :src="icon"
    alt=""
  >
</template>
