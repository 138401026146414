<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import { randomStr } from '@shell/utils/string';
import { set } from '@shell/utils/object';
export default {
  components: { LabeledInput, SectionTitle },
  props:      {
    value: {
      type:     Object,
      required: true,
    },
    mode: {
      type:    String,
      default: 'create',
    },
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  data() {
    if (this.mode === 'create') {
      set(this.value, 'core.config.secretKey', randomStr(50));
      set(this.value, 'core.config.bootstrapToken', randomStr(24));
      const originData = 'LWdyPoaQgK91YIjb';

      if (this.value.externalDatabase.password === originData) {
        set(this.value, 'externalDatabase.database', '');
        set(this.value, 'externalDatabase.host', '');
        set(this.value, 'externalDatabase.password', '');
        set(this.value, 'externalDatabase.user', '');
        set(this.value, 'externalRedis.host', '');
        set(this.value, 'externalRedis.password', '');
        set(this.value, 'ingress.hosts', ['']);
      }
    }

    return {};
  },
  methods: {
    willSave() {
      const errors = [];

      if (!this.value.ingress.hosts) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.hosts') }, true));
      } else if (!this.value.externalDatabase.host) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.mysql.host') }, true));
      } else if (!this.value.externalDatabase.database) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.mysql.database') }, true));
      } else if (!this.value.externalDatabase.user) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.mysql.user') }, true));
      } else if (!this.value.externalDatabase.password) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.mysql.password') }, true));
      } else if (!this.value.externalDatabase.port) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.mysql.port') }, true));
      } else if (!this.value.externalRedis.host) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.redis.host') }, true));
      } else if (!this.value.externalRedis.password) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.redis.password') }, true));
      } else if (!this.value.externalRedis.port) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.jumpserver.redis.port') }, true));
      }
      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },

    mergeValue(value, defaultValue) {
      return value === undefined || (typeof value === 'string' && !value.length) ? defaultValue : value;
    }
  },
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <template>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.ingress.hosts[0]"
            :label="t('catalog.charts.jumpserver.hosts')"
            :required="true"
          />
        </div>
      </div>
    </template>
    <template>
      <SectionTitle
        value="mysql"
        class="row mt-10"
      />
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalDatabase.host"
            :label="t('catalog.charts.jumpserver.mysql.host')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalDatabase.database"
            :label="t('catalog.charts.jumpserver.mysql.database')"
            :required="true"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalDatabase.user"
            :label="t('catalog.charts.jumpserver.mysql.user')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalDatabase.password"
            :label="t('catalog.charts.jumpserver.mysql.password')"
            :required="true"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalDatabase.port"
            :label="t('catalog.charts.jumpserver.mysql.port')"
            :required="true"
          />
        </div>
        <div class="col span-6" />
      </div>
    </template>
    <template>
      <SectionTitle
        value="redis"
        class="row mt-10"
      />
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalRedis.host"
            :label="t('catalog.charts.jumpserver.redis.host')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalRedis.password"
            :label="t('catalog.charts.jumpserver.redis.password')"
            :required="true"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.externalRedis.port"
            :label="t('catalog.charts.jumpserver.redis.port')"
            :required="true"
          />
        </div>
        <div class="col span-6" />
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">

</style>
