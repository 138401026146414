<script>
import { randomStr } from '@shell/utils/string';
import { LabeledInput } from '@components/Form/LabeledInput';
import CopyToClipboard from '@shell/components/CopyToClipboard';
import AsyncButton from '@shell/components/AsyncButton';
import { LOGGED_OUT, SETUP } from '@shell/config/query-params';
import { NORMAN, MANAGEMENT } from '@shell/config/types';
import { findBy } from '@shell/utils/array';
import { Checkbox } from '@components/Form/Checkbox';
import { getVendor, getProduct, setVendor } from '@shell/config/private-label';
import { RadioGroup } from '@components/Form/Radio';
import { setSetting } from '@shell/utils/settings';
import { SETTING } from '@shell/config/settings';
import { _ALL_IF_AUTHED } from '@shell/plugins/dashboard-store/actions';
import { isDevBuild } from '@shell/utils/version';
import { exceptionToErrorsArray } from '@shell/utils/error';
import Password from '@shell/components/form/Password';
import { applyProducts } from '@shell/store/type-map';
import BrandImage from '@shell/components/BrandImage';

const calcIsFirstLogin = (store) => {
  const firstLoginSetting = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.FIRST_LOGIN);

  return firstLoginSetting?.value === 'true';
};

const calcMustChangePassword = async(store) => {
  await store.dispatch('auth/getUser');

  const out = store.getters['auth/v3User']?.mustChangePassword;

  return out;
};

export default {
  layout: 'unauthenticated',

  data() {
    return {
      passwordOptions: [
        { label: this.t('setup.useRandom'), value: true },
        { label: this.t('setup.useManual'), value: false }],
    };
  },

  async middleware({ store, redirect, route } ) {
    try {
      await store.dispatch('management/findAll', {
        type: MANAGEMENT.SETTING,
        opt:  {
          load: _ALL_IF_AUTHED, url: `/v1/${ MANAGEMENT.SETTING }`, redirectUnauthorized: false
        }
      });
    } catch (e) {
    }

    const isFirstLogin = await calcIsFirstLogin(store);
    const mustChangePassword = await calcMustChangePassword(store);

    if (isFirstLogin) {
      // Always show setup if this is the first log in
      return;
    } else if (mustChangePassword) {
      // If the password needs changing and this isn't the first log in ensure we have the password
      if (!!store.getters['auth/initialPass']) {
        // Got it... show setup
        return;
      }
      // Haven't got it... redirect to log in so we get it
      await store.dispatch('auth/logout', null, { root: true });

      return redirect(302, `/pai/login?${ LOGGED_OUT }`);
    }

    // For all other cases we don't need to show setup
    return redirect('/pai');
  },

  components: {
    AsyncButton, LabeledInput, CopyToClipboard, Checkbox, RadioGroup, Password, BrandImage
  },

  async asyncData({ route, req, store }) {
    const telemetrySetting = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.TELEMETRY);
    const serverUrlSetting = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.SERVER_URL);
    const rancherVersionSetting = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.VERSION_RANCHER);
    let telemetry = true;

    if (telemetrySetting?.value && telemetrySetting.value !== 'prompt') {
      telemetry = telemetrySetting.value !== 'out';
    } else if (!rancherVersionSetting?.value || isDevBuild(rancherVersionSetting?.value)) {
      telemetry = false;
    }

    let plSetting;

    try {
      await store.dispatch('management/findAll', {
        type: MANAGEMENT.SETTING,
        opt:  {
          load: _ALL_IF_AUTHED, url: `/v1/${ MANAGEMENT.SETTING }`, redirectUnauthorized: false
        },
      });

      plSetting = store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.PL);
    } catch (e) {
      // Older versions used Norman API to get these
      plSetting = await store.dispatch('rancher/find', {
        type: 'setting',
        id:   SETTING.PL,
        opt:  { url: `/v3/settings/${ SETTING.PL }` }
      });
    }

    if (plSetting.value?.length && plSetting.value !== getVendor()) {
      setVendor(plSetting.value);
    }

    const productName = plSetting.default;

    const principals = await store.dispatch('rancher/findAll', { type: NORMAN.PRINCIPAL, opt: { url: '/v3/principals' } });
    const me = findBy(principals, 'me', true);

    const current = route.query[SETUP] || store.getters['auth/initialPass'];
    const v3User = store.getters['auth/v3User'] ?? {};

    const mcmFeature = await store.dispatch('management/find', {
      type: MANAGEMENT.FEATURE, id: 'multi-cluster-management', opt: { url: `/v1/${ MANAGEMENT.FEATURE }/multi-cluster-management` }
    });

    const mcmEnabled = mcmFeature?.spec?.value || mcmFeature?.status?.default;

    let serverUrl;

    if (serverUrlSetting?.value) {
      serverUrl = serverUrlSetting.value;
    } else if ( process.server ) {
      serverUrl = req.headers.host;
    } else {
      serverUrl = window.location.origin;
    }

    const isFirstLogin = await calcIsFirstLogin(store);
    const mustChangePassword = await calcMustChangePassword(store);

    return {
      productName,
      vendor:  getVendor(),
      product: getProduct(),
      step:    parseInt(route.query.step, 10) || 1,

      useRandom:   true,
      haveCurrent: !!current,
      username:    me?.loginName || 'admin',
      isFirstLogin,
      mustChangePassword,
      current,
      password:    randomStr(),
      confirm:     '',

      v3User,

      serverUrl,
      mcmEnabled,

      telemetry,

      eula: false,
      principals,

      errors:       [],
      passwordShow: false,
      confirmShow:  false,
    };
  },

  computed: {
    saveEnabled() {
      if ( !this.eula && this.isFirstLogin) {
        return false;
      }

      if ( this.mustChangePassword ) {
        if ( !this.current ) {
          return false;
        }

        if ( !this.useRandom ) {
          if ( !this.password || this.password !== this.confirm ) {
            return false;
          }
        }
      }

      return true;
    },

    me() {
      const out = findBy(this.principals, 'me', true);

      return out;
    }
  },

  watch: {
    useRandom(neu) {
      if (neu) {
        this.password = randomStr();
      } else {
        this.password = '';
      }
    }
  },

  methods: {
    async save() {
      if (!this.useRandom) {
        if (!this.password) {
          this.$message.error(this.t('login.tips.emptyPassword'));

          return;
        } else if (!this.confirm) {
          this.$message.error(this.t('login.tips.emptynewPassword'));

          return;
        } else if (this?.password !== this?.confirm) {
          this.$message.error(this.t('login.tips.samePassword'));

          return;
        } else if (this?.password?.length < 12 || this?.confirm?.length < 12) {
          this.$message.error(this.t('login.tips.passwordLength'));

          return;
        }
      }

      const promises = [];

      try {
        await applyProducts(this.$store, this.$plugin);
        await this.$store.dispatch('loadManagement');

        if ( this.mustChangePassword ) {
          await this.$store.dispatch('rancher/request', {
            url:    '/v3/users?action=changepassword',
            method: 'post',
            data:   {
              currentPassword: this.current,
              newPassword:     this.password
            },
          });
        } else {
          promises.push(setSetting(this.$store, SETTING.FIRST_LOGIN, 'false'));
        }

        const user = this.v3User;

        user.mustChangePassword = false;
        this.$store.dispatch('auth/gotUser', user);

        if (this.isFirstLogin) {
          promises.push( setSetting(this.$store, SETTING.EULA_AGREED, (new Date()).toISOString()) );
          promises.push( setSetting(this.$store, SETTING.TELEMETRY, this.telemetry ? 'in' : 'out') );

          if ( this.mcmEnabled && this.serverUrl ) {
            promises.push( setSetting(this.$store, SETTING.SERVER_URL, this.serverUrl) );
          }
        }

        await Promise.all(promises);

        setTimeout(() => {
          // buttonCb(true);
          this.done();
        }, 2000);
      } catch (err) {
        console.error(err) ; // eslint-disable-line no-console
        this.$message.error(err);
      }
    },

    done() {
      this.$router.replace('/pai');
    },
    hiddenPassword(val) {
      if (val === 'password') {
        this.passwordShow = !this.passwordShow;
      } else {
        this.confirmShow = !this.confirmShow;
      }
    }
  },
};
</script>

<template>
  <div class="setup">
    <div class="setupInner">
      <div class="left">
        <div class="border" />
        <img
          src="../../assets/images/login/balls.png"
          alt=""
          class="leftBackImage"
        >
      </div>
      <div class="main">
        <img
          src="../../assets/images/logo/logo-h.png"
          alt=""
        >
        <div class="title">
          <p>{{ t('setup.welcome', {product}) }}</p>
        </div>
        <el-form
          ref="form"
          :style="[useRandom ? { 'margin-top': '30px' } : {'margin-top': '10px'}]"
          :hide-required-asterisk="true"
        >
          <template v-if="mustChangePassword">
            <el-form-item
              v-if="!haveCurrent"
              prop="current"
            >
              <el-input
                v-model.trim="current"
                style="width: 300px"
                prefix-icon="iconfont el-icon-lock"
                clearable
                :placeholder="t('setup.currentPassword')"
                type="password"
                autocomplete="off"
              />
            </el-form-item>

            <!-- For password managers... -->
            <input
              type="hidden"
              name="username"
              autocomplete="username"
              :value="username"
            >
            <el-radio-group v-model="useRandom">
              <el-radio :label="true">
                {{ t('setup.useRandom') }}
              </el-radio>
              <br>
              <el-radio :label="false">
                {{ t('setup.useManual') }}
              </el-radio>
            </el-radio-group>
            <el-form-item
              v-if="useRandom"
              prop="password"
            >
              <el-input
                v-model.trim="password"
                style="width: 300px"
                prefix-icon="iconfont el-icon-lock"
                :placeholder="t('setup.newPassword')"
                :disabled="useRandom"
              >
                <a
                  slot="append"
                ><CopyToClipboard
                  :text="password"
                  class="btn-sm"
                /></a>
              </el-input>
            </el-form-item>
            <el-form-item
              v-else
              prop="password"
            >
              <el-input
                v-model.trim="password"
                style="width: 300px"
                prefix-icon="iconfont el-icon-lock"
                clearable
                :placeholder="t('setup.newPassword')"
                :type="passwordShow ? 'text':'password'"
                autocomplete="off"
              >
                <a
                  slot="append"
                  href="#"
                  @click="hiddenPassword('password')"
                >{{ !passwordShow? t('login.tips.show'):t('login.tips.hidden') }}</a>
              </el-input>
            </el-form-item>
            <el-form-item
              v-show="!useRandom"
              prop="confirm"
            >
              <el-input
                v-model.trim="confirm"
                style="width: 300px"
                prefix-icon="iconfont el-icon-lock"
                clearable
                :placeholder="t('setup.confirmPassword')"
                :type="confirmShow ? 'text':'password'"
                autocomplete="off"
              >
                <a
                  slot="append"
                  href="#"
                  @click="hiddenPassword('confirm')"
                >{{ !confirmShow?'显示':'隐藏' }}</a>
              </el-input>
            </el-form-item>
            <div class="passwordTips">
              <i class="el-icon-info" />
              <span
                v-clean-html="t(isFirstLogin ? 'setup.setPassword' : 'login.tips.newUserSetPassword', { username }, true)"
              />
            </div>
          </template>

          <template v-if="isFirstLogin">
            <template v-if="mcmEnabled">
              <hr
                v-if="mustChangePassword"
                class="mt-20 mb-20"
              >
              <p>
                <t
                  k="setup.serverUrl.tip"
                  :raw="true"
                />
              </p>
              <div class="mt-20">
                <LabeledInput
                  v-model="serverUrl"
                  :label="t('setup.serverUrl.label')"
                  data-testid="setup-server-url"
                />
              </div>
            </template>

            <div class="checkbox mt-40">
              <Checkbox
                id="checkbox-telemetry"
                v-model="telemetry"
              >
                <template #label>
                  <t
                    k="setup.telemetry"
                    :raw="true"
                    :name="productName"
                  />
                </template>
              </Checkbox>
            </div>
            <div class="checkbox pt-10 eula">
              <Checkbox
                id="checkbox-eula"
                v-model="eula"
                data-testid="setup-agreement"
              >
                <template #label>
                  <t
                    k="setup.eula"
                    :raw="true"
                    :name="productName"
                  />
                </template>
              </Checkbox>
            </div>
          </template>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 300px; margin-top: 20px"
              native-type="submit"
              @click.prevent="save('form')"
            >
              {{ t('login.label.action') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.setup {
  height: 100%;
  background-color: #003054;
  .setupInner{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    .left{
      width: 536px;
      height: 536px;
      position: relative;
      .border{
        width: 100%;
        height: 100%;
        border: 7px dashed #023A68;
        animation: fadenum 10s linear infinite;
        border-radius: 50%;
      }
      @keyframes fadenum{
        0%{transform: rotate(0deg);}
        100%{transform:rotate(360deg);}
      }
      .leftBackImage{
        position: absolute;
        top: 46px;
        left: 49px;
      }
    }

    .main{
      width: 420px;
      height: 432px;
      background-color: #053A62;
      text-align: center;
      padding: 30px;
      .title{
        padding-top: 10px;
        color: #fff;
        font-size: 20px;
      }
      .radio-group {
        text-align: left;
      }
      .radio-container span {
        color: #FFFFFF;
      }
      .passwordTips {
        color: #FFFFFF;
        font-size: 12px;
        padding-left: 26px;
      }
      A:hover, A:active {
        color: #3D98D3;
      }
    }
  }
  ::v-deep .el-input__inner, .el-input.is-disabled .el-input__inner {
    background: #13456B;
    border: 1px solid #456D8A;
    color: #A6B1B9;
  }
  ::v-deep .el-form-item__error{
    margin-left: 46px;
  }
  ::v-deep .el-radio-group {
    font-size: 0;
    text-align: left;
    margin-left: -117px;
    margin-bottom: 6px;
  }
  ::v-deep .el-input-group__append, .el-input-group__prepend {
    border-right: 1px solid #456D8A;
    border-top:1px solid #456D8A;
    border-bottom: 1px solid #456D8A;
    background-color: #053A62;
    padding: 0 6px;
  }
  ::v-deep .el-radio {
    color: #FFFFFF;
  }
  ::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #FFFFFF;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #053A62;
  }
  ::v-deep .el-radio__inner::after {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #3D98D3;
  }
  ::v-deep .el-radio__inner {
    background: #053A62;
  }
}
</style>
