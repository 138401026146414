<template>
  <div class="cluster-single-resource cluster-flex">
    <div class="resource-main bgCard">
      <div class="cluster-title">
        {{ t('pai.detail.vmset.computingResources') }}
        <!--        计算资源-->
      </div>
      <div class="cluster-flex">
        <div class="resource-main-left">
          <img
            src="../images/count.png"
          >
        </div>
        <div class="resource-main-right">
          <div class="right-data cluster-flex">
            <div>{{ t('pai.overview.cluster') + t('pai.detail.vmset.physicalNode') }}：</div>
            <div><span class="cluster-num">{{ nodeLength }}</span>个</div>
          </div>
          <div
            class="right-data cluster-flex"
          >
            <div>{{ t('pai.overview.pod') + t('pai.edit.machine.size') }}：</div>
            <div><span class="cluster-num">{{ podData.length }}</span><span>/{{ containerAll }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="resource-main bgCard">
      <div class="cluster-title">
        {{ t('pai.detail.vmset.serviceLoad') }}
      </div>
      <div class="cluster-flex">
        <div class="resource-main-left">
          <img
            src="../images/load.png"
          >
        </div>
        <div class="resource-main-right">
          <div class="right-data cluster-flex">
            <div>{{ t('pai.menu.vmset') }}：</div>
            <div><span class="cluster-num">{{ cloudData.length }}</span>个</div>
          </div>
          <div class="right-data cluster-flex">
            <div>{{ t('pai.menu.pod') }}：</div>
            <div><span class="cluster-num">{{ podData.length }}</span>个</div>
          </div>
        </div>
      </div>
    </div>
    <div class="resource-main bgCard">
      <div class="cluster-title">
        {{ t('pai.menu.k8s.cni.cncf.io.networkattachmentdefinition') }}
      </div>
      <div class="cluster-flex">
        <div class="resource-main-left">
          <img
            src="../images/internet.png"
          >
        </div>
        <!-- 数据动态-->
        <div class="resource-main-right">
          <div
            class="right-data cluster-flex"
            style="border-bottom: 1px dashed #BBBBBB;"
          >
            <div>{{ t('pai.overview.cluster') + t('pai.detail.vmset.virtualNetwork') }}：</div>
            <div><span class="cluster-num">{{ netWorkLength }}</span>个</div>
          </div>
        </div>
      </div>
    </div>
    <div class="resource-main bgCard">
      <div
        class="cluster-title"
      >
        {{ t('pai.menu.k8s.cni.cncf.io.storageattachmentdefinition') }}
      </div>
      <div class="cluster-flex">
        <div class="resource-main-left">
          <img
            src="../images/disk.png"
          >
        </div>
        <div class="resource-main-right">
          <div
            v-if="longhornSchema"
            class="right-data cluster-flex"
          >
            <div style="width: 45%">
              {{ t('pai.detail.vmset.distStorage') }}：
            </div>
            <div class="storageNum">
              <span class="cluster-num">{{ longhornRun }}</span>{{ longhornRunUntis }}/
              <span>{{ longhornAll }}</span>{{ longhornAllUntis }}
            </div>
          </div>
          <div
            class="right-data cluster-flex"
            :style="[!longhornSchema ? { 'border-bottom': '1px dashed #BBBBBB' } : {'border-bottom': 'none'}]"
          >
            <div style="width: 45%">
              {{ t('pai.detail.vmset.localStorage') }}：
            </div>
            <div class="storageNum">
              <span class="cluster-num">{{ localRun }}</span>{{ localRunUntis }}/
              <span>{{ localAll }}</span>{{ localAllUntis }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LONGHORN, MANAGEMENT, POD } from '@shell/config/types';
import { numDelivery } from '../../../../../utils/units';

export default {
  name:  'ClusterSingleResource',
  props: {
    cloudData: {
      type:    Array,
      default: () => {
        return [];
      }
    },
    podData: {
      type:    Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    const params = this.$route.params;

    return {
      nodeLength:       0,
      containerAll:     0,
      netWorkLength:    0,
      longhornAll:      0,
      longhornRun:      0,
      localAll:         0,
      localRun:         0,
      longhornRunUntis: 'G',
      longhornAllUntis: 'G',
      localRunUntis:    'G',
      localAllUntis:    'G',
      params
    };
  },
  async fetch() {
    // 集群物理节点
    try {
      const nodeData = await this.$store.dispatch('cluster/findAll', { type: 'node' });

      this.nodeLength = nodeData.length;
    } catch (e) {
      console.log(e);
    }

    // 容器组容量
    try {
      const contanerData = this.$store.getters['management/all'](MANAGEMENT.CLUSTER);

      contanerData.forEach((item) => {
        if (item.id === this.params.cluster) {
          this.containerAll = item.status.capacity.pods;
        }
      });
    } catch (e) {
      console.log(e);
    }

    //  网络资源
    try {
      const netWorkData = await this.$store.dispatch('cluster/findAll', { type: 'k8s.cni.cncf.io.networkattachmentdefinition' });

      this.netWorkLength = netWorkData.length;
    } catch (e) {
      console.log(e);
    }

    //  分布式存储
    const longhornParamsAll = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(longhorn_disk_capacity_bytes)` };
    const longhornAllData = await this.$store.dispatch('pai-common/getUse', longhornParamsAll);

    const longhornParamsRun = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(longhorn_disk_usage_bytes)` };
    const longhornRunData = await this.$store.dispatch('pai-common/getUse', longhornParamsRun);

    //  本地存储
    const localParamsAll = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(node_filesystem_size_bytes{mountpoint="/var/lib/rancher/k3s/storage"})` };
    const localAllData = await this.$store.dispatch('pai-common/getUse', localParamsAll);
    const localParamsRun = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(node_filesystem_size_bytes{mountpoint="/var/lib/rancher/k3s/storage"})-sum(node_filesystem_free_bytes{mountpoint="/var/lib/rancher/k3s/storage"})` };
    const localRunData = await this.$store.dispatch('pai-common/getUse', localParamsRun);

    // 没有配置存储池时的查询方法
    const localParamsAllNo = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(node_filesystem_size_bytes{mountpoint="/"})` };
    const localAllDataNo = await this.$store.dispatch('pai-common/getUse', localParamsAllNo);
    const localParamsRunNo = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum(node_filesystem_size_bytes{mountpoint="/"})-sum(node_filesystem_free_bytes{mountpoint="/"})` };
    const localRunDataNo = await this.$store.dispatch('pai-common/getUse', localParamsRunNo);

    const longhornAll = numDelivery([longhornAllData?.data?.data?.result[0]?.value[1]] / 1024 / 1024 / 1024);
    const longhornRun = numDelivery([longhornRunData?.data?.data?.result[0]?.value[1]] / 1024 / 1024 / 1024);
    const localAll = numDelivery([localAllData?.data?.data?.result[0]?.value[1] ? localAllData?.data?.data?.result[0]?.value[1] : localAllDataNo?.data?.data?.result[0]?.value[1]] / 1024 / 1024 / 1024);
    const localRun = numDelivery([localRunData?.data?.data?.result[0]?.value[1] ? localRunData?.data?.data?.result[0]?.value[1] : localRunDataNo?.data?.data?.result[0]?.value[1]] / 1024 / 1024 / 1024);

    this.longhornAll = longhornAll > 1000 ? numDelivery(longhornAll / 1024) : longhornAll;
    this.longhornRun = longhornRun > 1000 ? numDelivery(longhornRun / 1024) : longhornRun;
    this.longhornAllUntis = longhornAll > 1000 ? 'T' : 'G';
    this.longhornRunUntis = longhornRun > 1000 ? 'T' : 'G';

    this.localAll = localAll > 1000 ? numDelivery(localAll / 1024) : localAll;
    this.localRun = localRun > 1000 ? numDelivery(localRun / 1024) : localRun;
    this.localAllUntis = localAll > 1000 ? 'T' : 'G';
    this.localRunUntis = localRun > 1000 ? 'T' : 'G';
  },
  computed: {
    longhornSchema() {
      return this.$store.getters['cluster/schemaFor'](LONGHORN.NODES);
    }
  }
};
</script>

<style lang="scss" scoped>
.cluster-single-resource{
  .resource-main{
    width: 25%;
    margin-right: 14px;
  }
  .resource-main:last-child{
    margin-right: 0px;
  }

  .resource-main-left{
    width: 60px;
    height: 60px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    margin-top: 10px;
    background-color: #F0F2F5;

    img{
      width: 35px;
      height: 35px;
    }
  }
  .resource-main-right{
    width: 80%;
    padding: 0px 6px 0px 6px;
    .right-data{
      margin: 8px 0px;
      border-bottom: 1px dashed  #BBBBBB;
    }
    .right-data:last-child {
      border-bottom: 0px;
    }
  }
  .storageNum{
    width: 55%;
    text-align: right;
  }
}
</style>
