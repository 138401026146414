<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import UnitInput from '@shell/components/form/UnitInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect';

export default {
  components: {
    LabeledInput,
    UnitInput,
    SectionTitle,
    LabeledSelect
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  methods: {
    willSave() {
      const errors = [];

      if (this.value.auth.username && this.value.auth.username === 'root') {
        errors.push(this.t('catalog.charts.mysql.usernameTips'));
      }
      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },
  }
};
</script>

<template>
  <div class="chartMysql">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.architecture"
          :label="t('catalog.charts.mysql.architecture')"
          :options="['standalone', 'replication']"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.auth.rootPassword"
          type="password"
          :label="t('catalog.charts.mysql.rootPassword')"
        />
      </div>
    </div>

    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.auth.createDatabase"
          :label="t('catalog.charts.mysql.createDatabase')"
          :options="['true', 'false']"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.auth.database"
          :label="t('catalog.charts.mysql.database')"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.auth.username"
          :label="t('catalog.charts.mysql.username')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.auth.password"
          type="password"
          :label="t('catalog.charts.mysql.password')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.chartMysql {
  margin-bottom: 50px;
}

</style>
