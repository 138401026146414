<script>
import AsyncButton, { ASYNC_BUTTON_STATES } from '@/shell/components/AsyncButton.vue';
import InstallMixin from '~/pkg/pai/mixins/install';

export default {
  name:       'QuickInstall',
  components: { AsyncButton },
  mixins:     [
    InstallMixin,
  ],
  props: {
    /**
     * Mode maps to keys in asyncButton.* translations
     */
    version: {
      type:     Object,
      required: true,
    },
    versionInfo: {
      type:     Object,
      required: true,
    },
    chart:{
      type:     Object,
      required: true,
    },
    repo:{
      type:     Object,
      required: true,
    }
  },
  data() {
    return { currentPhase: ASYNC_BUTTON_STATES.ACTION };
  },
  methods: {
    clicked() {
      this.$confirm(this.t('pai.vmset.tips.installApps'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning',
      })
        .then(async() => {
          this.currentPhase = ASYNC_BUTTON_STATES.WAITING;
          await this.finish();
          if (this.errors && this.errors.length > 0) {
            this.currentPhase = ASYNC_BUTTON_STATES.ERROR;
            this.$message.error(this.errors[0]);
          } else {
            this.currentPhase = ASYNC_BUTTON_STATES.SUCCESS;
          }
        });
    },
  },
};
</script>
<template>
  <AsyncButton
    :currentPhase="currentPhase"
    :manual="true"
    mode="quickInstall"
    style="margin-right: 5px"
    class="btn role-primary"
    @click="clicked"
  />
</template>
<style lang="scss" scoped>

</style>
