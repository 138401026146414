<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
export default {
  components: { LabeledInput, LabeledSelect },
  props:      {
    value: {
      type:     Object,
      required: true,
    }
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  methods: {
    willSave() {
      const errors = [];

      if (!this.value.master.count) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.redis.masterCount') }, true));
      } else if (!this.value.replica.replicaCount) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.redis.replicaCount') }, true));
      }
      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },
  }
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.architecture"
          :label="t('catalog.charts.redis.architecture')"
          :options="['standalone', 'replication']"
        />
      </div>
      <div class="col span-6">
        <LabeledSelect
          v-model="value.auth.enabled"
          :label="t('catalog.charts.redis.authEnabled')"
          :options="[{label:'true', value: true}, {label: 'false', value: false}]"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.auth.sentinel"
          :label="t('catalog.charts.redis.sentinelAuth')"
          :options="[{label:'true', value: true}, {label: 'false', value: false}]"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.auth.password"
          :label="t('catalog.charts.redis.password')"
          type="password"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.master.count"
          :label="t('catalog.charts.redis.masterCount')"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.replica.replicaCount"
          :label="t('catalog.charts.redis.replicaCount')"
          :required="true"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
