import CatalogApp from '@shell/models/catalog.cattle.io.app';
import { getDetailLocation } from '../utils';
import {
  _FLAGGED, CHART, FROM_TOOLS, NAME, NAMESPACE, REPO, REPO_TYPE, VERSION,
} from '@shell/config/query-params';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import StarMixin from '~/pkg/pai/mixins/star';
import { NORMAN_NAME } from '@shell/config/labels-annotations';

export default class extends StarMixin(CatalogApp) {
  get detailLocation() {
    return getDetailLocation(this);
  }

  get nameDisplay() {
    const out = this.metadata?.annotations?.[NORMAN_NAME] || this.spec?.name || this.metadata?.name || this.id || '';

    return out;
  }

  goToUpgrade(forceVersion, fromTools) {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      this.goToPaiUpgrade(forceVersion, fromTools);
    } else {
      super.goToUpgrade(forceVersion, fromTools);
    }
  }

  goToPaiUpgrade(forceVersion, fromTools) {
    const match = this.matchingChart(true);
    const versionName = this.spec?.chart?.metadata?.version;
    const query = {
      [NAMESPACE]: this.metadata.namespace,
      [NAME]:      this.metadata.name,
      [VERSION]:   forceVersion || versionName,
    };

    if (match) {
      query[REPO] = match.repoName;
      query[REPO_TYPE] = match.repoType;
      query[CHART] = match.chartName;
    }

    if (fromTools) {
      query[FROM_TOOLS] = _FLAGGED;
    }

    this.currentRouter()
      .push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-install`,
        params: {
          product: PRODUCT_NAME,
          cluster: this.$rootGetters['clusterId'],
        },
        query,
      });
  }
}
