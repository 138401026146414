<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import UnitInput from '@shell/components/form/UnitInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect';
import { set } from '@shell/utils/object';

export default {
  components: {
    LabeledInput,
    UnitInput,
    SectionTitle,
    LabeledSelect
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    let awsId = '';
    let awsKey = '';

    if (this.value?.credentials?.secretContents?.cloud) {
      const cloudStr = JSON.stringify(this.value?.credentials?.secretContents?.cloud);
      let idNum = 0;
      let keyNum = 0;

      if (cloudStr.includes('aws_access_key_id') && cloudStr.includes('aws_secret_access_key')) {
        idNum = this.value?.credentials?.secretContents?.cloud.indexOf('=');
        keyNum = this.value?.credentials?.secretContents?.cloud.lastIndexOf('=');

        awsId = (this.value?.credentials?.secretContents?.cloud.substr(idNum + 1))?.split('aws_secret_access_key')[0];
        awsKey = this.value?.credentials?.secretContents?.cloud.substr(keyNum + 1);
      } else if (cloudStr.includes('aws_access_key_id') && !cloudStr.includes('aws_secret_access_key')) {
        idNum = this.value?.credentials?.secretContents?.cloud.indexOf('=');
        awsId = (this.value?.credentials?.secretContents?.cloud.substr(idNum + 1));
      } else if (!cloudStr.includes('aws_access_key_id') && cloudStr.includes('aws_secret_access_key')) {
        keyNum = this.value?.credentials?.secretContents?.cloud.indexOf('=');
        awsKey = (this.value?.credentials?.secretContents?.cloud.substr(keyNum + 1));
      }
    }

    return {
      awsId,
      awsKey
    };
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  methods: {
    willSave() {
      const errors = [];

      if (!this.value.configuration.provider) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.velero.provider') }, true));
      } else if (!this.value.configuration.backupStorageLocation.bucket) {
        errors.push(this.t('validation.required', { key: this.t('catalog.charts.velero.bucket') }, true));
      }
      const AwsId = !!this.awsId ? `\naws_access_key_id=${ this.awsId }` : '';
      const AwsKey = !!this.awsKey ? `\naws_secret_access_key=${ this.awsKey }` : '';

      set(this.value, 'credentials.secretContents.cloud', `[default]${ AwsId }${ AwsKey }`);
      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },
  }
};
</script>

<template>
  <div class="chartVelero">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.configuration.provider"
          :label="t('catalog.charts.velero.provider')"
          required
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.configuration.backupStorageLocation.bucket"
          :label="t('catalog.charts.velero.bucket')"
          required
        />
      </div>
    </div>

    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.configuration.backupStorageLocation.config.region"
          :label="t('catalog.charts.velero.region')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.configuration.backupStorageLocation.config.s3Url"
          :label="t('catalog.charts.velero.s3Url')"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="awsId"
          :label="t('catalog.charts.velero.awsId')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="awsKey"
          :label="t('catalog.charts.velero.awsKey')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
