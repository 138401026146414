<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import UnitInput from '@shell/components/form/UnitInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect';

export default {
  components: {
    LabeledInput,
    UnitInput,
    SectionTitle,
    LabeledSelect
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    return {};
  },
  computed: {
    imagePolicyOptions() {
      return [{
        label: this.t('pai.detail.vmset.distStorage'),
        value: 'longhorn',
      }, {
        label: this.t('pai.detail.vmset.localStorage'),
        value: 'local-path',
      }];
    },
  },
  methods: {},
};
</script>

<template>
  <div class="chartHadoop">
    <!--  镜像  -->
    <div class="row mt-10">
      <SectionTitle
        :value="t('catalog.charts.hadoop.imageSetup')"
        class="row titleRow"
      />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.image.repository"
          :label="t('pai.vmset.image.url')"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.image.tag"
          :label="t('catalog.charts.hadoop.label')"
        />
      </div>
    </div>

    <!--  数据节点  -->
    <div class="row mt-10">
      <SectionTitle
        :value="t('catalog.charts.hadoop.nodeData')"
        class="row titleRow"
      />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.hdfs.dataNode.replicas"
          type="number"
          :label="t('pai.edit.chart.nodeCount')"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.dataNode.resources.requests.cpu"
          :label="t('containerResourceLimit.requestsCpu')"
          :base-unit="t('suffix.cores')"
          :output-modifier="true"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.dataNode.resources.requests.memory"
          :label="t('containerResourceLimit.requestsMemory')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.dataNode.resources.limits.cpu"
          :label="t('containerResourceLimit.limitsCpu')"
          :base-unit="t('suffix.cores')"
          :output-modifier="true"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.dataNode.resources.limits.memory"
          :label="t('containerResourceLimit.limitsMemory')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.persistence.dataNode.storageClass"
          :label="t('catalog.charts.wukong.storage')"
          :options="imagePolicyOptions"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.persistence.dataNode.size"
          :label="t('pai.detail.vmset.tab.diskManagement.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>

    <!--节点管理-->
    <div class="row mt-10">
      <SectionTitle
        :value="t('catalog.charts.hadoop.nodeManagement')"
        class="row titleRow"
      />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.nameNode.resources.requests.cpu"
          :label="t('containerResourceLimit.requestsCpu')"
          :base-unit="t('suffix.cores')"
          :output-modifier="true"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.nameNode.resources.requests.memory"
          :label="t('containerResourceLimit.requestsMemory')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.nameNode.resources.limits.cpu"
          :label="t('containerResourceLimit.limitsCpu')"
          :base-unit="t('suffix.cores')"
          :output-modifier="true"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.hdfs.nameNode.resources.limits.memory"
          :label="t('containerResourceLimit.limitsMemory')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.persistence.nameNode.storageClass"
          :label="t('catalog.charts.wukong.storage')"
          :options="imagePolicyOptions"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.persistence.nameNode.size"
          :label="t('pai.detail.vmset.tab.diskManagement.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.chartHadoop {
  margin-bottom: 50px;
  .titleRow {
    margin-top: 20px;
  }
}

</style>
