import { PRODUCT_NAME } from '../config/pai';
import Promise from 'lodash/_Promise';

const state = function() {
  return {
    allCpu: '', ramDashboard: '', cpuLine: [], ramLine: [], netLine: [], diskIOPSData: [], netMBpsData: [], diskBPSData: [], netLineForm: [], diskIOPSDataForm: [], diskData: []
  };
};

const mutations = {
  getCpuUsedRate(state, payload) {
    state.cpuLine = payload;
  },
  getRamUsedRate(state, payload) {
    state.ramLine = payload;
  },
  getNetLine(state, payload) {
    state.netLine = payload;
  },
  getDiskIOPS(state, payload) {
    state.diskIOPSData = payload;
  },
  getNetMBps(state, payload) {
    state.netMBpsData = payload;
  },
  getDiskBPS(state, payload) {
    state.diskBPSData = payload;
  },
  getAllCpu(state, payload) {
    state.allCpu = payload;
  },
  getRamDashboard(state, payload) {
    state.ramDashboard = payload;
  },
  getNetLinePlatForm(state, payload) {
    state.netLineForm = payload;
  },
  getDiskIOPSPlatForm(state, payload) {
    state.diskIOPSDataForm = payload;
  },
  getDisk(state, payload) {
    state.diskData = payload;
  }
};
const getters = {};
const actions = {
  getCpu({ commit }, payload) {
    const url = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=100 - (avg by (instance,namespace,pod) (irate(node_cpu_seconds_total{job="cattle-monitoring-system/paivm-pod-monitor", mode="idle", namespace="${ payload.namespace }", pod="${ payload.podName }"}[3m]) or irate(windows_cpu_time_total{job="cattle-monitoring-system/paivm-pod-monitor", mode="idle", namespace="${ payload.namespace }", pod="${ payload.podName }"}[3m]))) * 100`;

    return new Promise((resolve, reject) => {
      return this.$axios.get(url)
        .then((cpuLines) => {
          if (cpuLines && cpuLines?.status === 200) {
            let value = '';

            cpuLines.data?.data?.result?.map((result) => {
              value = (parseFloat(result.value[1])).toFixed(2);
            });
            commit('getAllCpu', value );
            resolve(value);
          }
          resolve('');
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  getRamDashboard({ commit }, payload) {
    const url = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=100 - (((node_memory_MemAvailable_bytes{job="cattle-monitoring-system/paivm-pod-monitor", namespace="${ payload.namespace }", pod="${ payload.podName }"} / node_memory_MemTotal_bytes) or (windows_os_physical_memory_free_bytes{job="cattle-monitoring-system/paivm-pod-monitor", namespace="${ payload.namespace }", pod="${ payload.podName }"} / windows_cs_physical_memory_bytes)) * 100)`;

    return new Promise((resolve, reject) => {
      return this.$axios.get(url)
        .then((ramLines) => {
          if (ramLines && ramLines?.status === 200) {
            let value = '';

            ramLines.data?.data?.result?.map((result) => {
              value = result.value[1] === '+Inf' || result.value[1] === 'NaN' ? 0 : (parseFloat(result.value[1])).toFixed(2);
            });
            commit('getRamDashboard', value );
            resolve(value);
          }
          resolve('');
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  getDisk({ commit }, payload) {
    const url = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=100 - (sum(node_filesystem_avail_bytes{job="cattle-monitoring-system/paivm-pod-monitor",device=~"/dev/.*",namespace="${ payload.namespace }",pod="${ payload.podName }"} or windows_logical_disk_free_bytes{job="cattle-monitoring-system/paivm-pod-monitor",namespace="${ payload.namespace }",pod="${ payload.podName }"}) by (namespace,pod)/sum(node_filesystem_size_bytes{job="cattle-monitoring-system/paivm-pod-monitor",device=~"/dev/.*",namespace="${ payload.namespace }",pod="${ payload.podName }"} or windows_logical_disk_size_bytes{job="cattle-monitoring-system/paivm-pod-monitor",namespace="${ payload.namespace }",pod="${ payload.podName }"}) by (namespace,pod) * 100)`;

    return new Promise((resolve, reject) => {
      return this.$axios.get(url)
        .then((diskLines) => {
          if (diskLines && diskLines?.status === 200) {
            let value = '';

            diskLines.data?.data?.result?.map((result) => {
              value = result.value[1] === '+Inf' || result.value[1] === 'NaN' ? 0 : (parseFloat(result.value[1])).toFixed(2);
            });
            commit('getDisk', value );
            resolve(value);
          }
          resolve('');
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  getCpuUsedRate({ commit }, payload) {
    const url = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=100 - (avg by (namespace,pod) (irate(node_cpu_seconds_total{job="cattle-monitoring-system/paivm-pod-monitor", mode="idle", namespace="${ payload.namespace }", pod="${ payload.podName }"}[3m]) or irate(windows_cpu_time_total{job="cattle-monitoring-system/paivm-pod-monitor", mode="idle", namespace="${ payload.namespace }", pod="${ payload.podName }"}[3m]))) * 100&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;

    return new Promise((resolve, reject) => {
      return this.$axios.get(url)
        .then((cpuLines) => {
          if (cpuLines && cpuLines?.status === 200) {
            const values = [];

            cpuLines.data?.data?.result?.map((result) => {
              const cpu = { pod: 'cpu利用率' };
              const cpuValuesDate = [];
              const cpuValuesData = [];

              result?.values?.map((i) => {
                const now = new Date(i[0] * 1000);

                cpuValuesDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                cpuValuesData.push((parseFloat(i[1])).toFixed(2));
              });
              cpu.nodeValuesDate = cpuValuesDate;
              cpu.nodeValuesData = cpuValuesData;
              values.push(cpu);
            });
            commit('getCpuUsedRate', values );
            resolve(values);
          }
        })
        .catch((err) => {
          commit('getCpuUsedRate', []);
          console.log(err);
        });
    });
  },
  getRamUsedRate({ commit }, payload) {
    const url = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=100 - (avg by (namespace,pod)((node_memory_MemAvailable_bytes{job="cattle-monitoring-system/paivm-pod-monitor", namespace="${ payload.namespace }", pod="${ payload.podName }"} / node_memory_MemTotal_bytes) or (windows_os_physical_memory_free_bytes{job="cattle-monitoring-system/paivm-pod-monitor", namespace="${ payload.namespace }", pod="${ payload.podName }"} / windows_cs_physical_memory_bytes)) * 100)&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;

    return new Promise((resolve, reject) => {
      return this.$axios.get(url)
        .then((ramLines) => {
          if (ramLines && ramLines?.status === 200) {
            const values = [];
            let objResult = {};

            ramLines.data?.data?.result?.map((result) => {
              const ram = { pod: '内存利用率' };
              const ramValuesDate = [];
              const ramValuesData = [];

              objResult = ramLines.data?.data?.result.length > 1 ? ramLines.data?.data?.result?.at(-1) : result;
              objResult?.values?.map((i) => {
                const now = new Date(i[0] * 1000);

                ramValuesDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                ramValuesData.push(i[1] === '+Inf' ? 0 : parseFloat(i[1]).toFixed(2));
              });
              ram.nodeValuesDate = ramValuesDate;
              ram.nodeValuesData = ramValuesData;
              values.push(ram);
            });
            commit('getRamUsedRate', values );
            resolve(values);
          }
        })
        .catch((err) => {
          commit('getRamUsedRate', []);
          console.log(err);
        });
    });
  },
  getNetLine({ commit }, payload) {
    const urlHuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=sum(rate(container_network_receive_bytes_total{id=~"/kubepods.*",namespace="${ payload.namespace }",pod="${ payload.podName }"}[3m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlPuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=sum(rate(container_network_transmit_bytes_total{id=~"/kubepods.*",namespace="${ payload.namespace }",pod="${ payload.podName }"}[3m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const values = [];
    const queryHuff = new Promise((resolve, reject) => {
      this.$axios.get(urlHuff)
        .then((netHuffLine) => {
          if (netHuffLine && netHuffLine?.status === 200) {
            netHuffLine.data?.data?.result.map((result) => {
              const netEnter = { node: '网络流入总量' };
              const netHuffDate = [];
              const netHuffData = [];

              result?.values?.map((i) => {
                const now = new Date(i[0] * 1000);

                netHuffDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                netHuffData.push((parseFloat((i[1] / 1024 / 1024) )).toFixed(2));
              });
              netEnter.nodeValuesDate = netHuffDate;
              netEnter.nodeValuesData = netHuffData;
              values.push(netEnter);
            });
          }
        });
      resolve(values);
    });
    const queryPuff = new Promise((resolve, reject) => {
      this.$axios.get(urlPuff).then((netPuffLine) => {
        if (netPuffLine && netPuffLine?.status === 200) {
          netPuffLine.data?.data?.result.map((result) => {
            const netOut = { node: '网络流出总量' };
            const netPuffDate = [];
            const netPuffData = [];

            result?.values?.map((i) => {
              const now = new Date(i[0] * 1000);

              netPuffDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              netPuffData.push((parseFloat((i[1] / 1024 / 1024) )).toFixed(2));
            });
            netOut.nodeValuesDate = netPuffDate;
            netOut.nodeValuesData = netPuffData;
            values.push(netOut);
          });
        }
      });
      resolve(values);
    });

    return Promise.all([queryHuff, queryPuff]).then((res) => {
      commit('getNetLine', values );

      return values;
      // resolve(values);
    }).catch((err) => {
      commit('getNetLine', []);
      console.log(err);
    });
  },
  getDiskIOPS({ commit }, payload) {
    const urlRead = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=rate(node_disk_reads_completed_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',device=~"[a-z]*[a-z]"}[5m]) or sum(rate(windows_logical_disk_reads_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',volume=~"[A-Z]:"}[5m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlWrite = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=rate(node_disk_writes_completed_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',device=~"[a-z]*[a-z]"}[5m]) or sum(rate(windows_logical_disk_writes_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',volume=~"[A-Z]:"}[5m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;

    const values = [];
    const queryRead = new Promise((resolve, reject) => {
      this.$axios.get(urlRead)
        .then((dataRead) => {
          if (dataRead && dataRead?.status === 200) {
            dataRead.data?.data?.result.map((result) => {
              const IOPSRead = { node: '磁盘读取' };
              const IOPSReadDate = [];
              const IOPSReadData = [];

              result.values.map((i) => {
                const now = new Date(i[0] * 1000);

                IOPSReadDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                IOPSReadData.push((parseFloat(i[1])).toFixed(2));
              });
              IOPSRead.nodeValuesDate = IOPSReadDate;
              IOPSRead.nodeValuesData = IOPSReadData;
              values.push(IOPSRead);
            });
          }
        });
      resolve(values);
    });
    const queryWrite = new Promise((resolve, reject) => {
      this.$axios.get(urlWrite).then((dataWrite) => {
        if (dataWrite && dataWrite?.status === 200) {
          dataWrite?.data?.data?.result.map((result) => {
            const IOPSWrite = { node: '磁盘写入' };
            const IOPSWriteDate = [];
            const IOPSWriteData = [];

            result.values.map((i) => {
              const now = new Date(i[0] * 1000);

              IOPSWriteDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              IOPSWriteData.push((parseFloat(i[1])).toFixed(2));
            });
            IOPSWrite.nodeValuesDate = IOPSWriteDate;
            IOPSWrite.nodeValuesData = IOPSWriteData;
            values.push(IOPSWrite);
          });
        }
      });
      resolve(values);
    });

    return Promise.all([queryRead, queryWrite]).then((res) => {
      commit('getDiskIOPS', values );

      return values;
      // resolve(values);
    }).catch((err) => {
      commit('getDiskIOPS', []);
      console.log(err);
    });
  },
  getNetMBps({ commit }, payload) { // 网络IO包数量
    const urlNetIOHuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil(sum(rate(container_network_receive_packets_total{id=~"/kubepods.*",namespace="${ payload.namespace }",pod="${ payload.podName }"}[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlNetIOPuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil(sum(rate(container_network_transmit_packets_total{id=~"/kubepods.*",namespace="${ payload.namespace }",pod="${ payload.podName }"}[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;

    const values = [];
    const queryNetIOHuff = new Promise((resolve, reject) => {
      this.$axios.get(urlNetIOHuff)
        .then((dataRead) => {
          if (dataRead && dataRead?.status === 200) {
            dataRead.data?.data?.result.map((result) => {
              const MBpsRead = { node: '网络流入包数量' };
              const MBpsReadDate = [];
              const MBpsReadData = [];

              result.values.map((i) => {
                const now = new Date(i[0] * 1000);

                MBpsReadDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                MBpsReadData.push((parseFloat(i[1])).toFixed(2));
              });
              MBpsRead.nodeValuesDate = MBpsReadDate;
              MBpsRead.nodeValuesData = MBpsReadData;
              values.push(MBpsRead);
            });
          }
        });
      resolve(values);
    });
    const queryNetIOPuff = new Promise((resolve, reject) => {
      this.$axios.get(urlNetIOPuff).then((dataWrite) => {
        if (dataWrite && dataWrite?.status === 200) {
          dataWrite.data?.data?.result.map((result) => {
            const MBpsWrite = { node: '网络流出包数量' };
            const MBpsWriteDate = [];
            const MBpsWriteData = [];

            result.values.map((i) => {
              const now = new Date(i[0] * 1000);

              MBpsWriteDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              MBpsWriteData.push((parseFloat(i[1])).toFixed(2));
            });
            MBpsWrite.nodeValuesDate = MBpsWriteDate;
            MBpsWrite.nodeValuesData = MBpsWriteData;
            values.push(MBpsWrite);
          });
        }
      });
      resolve(values);
    });

    return Promise.all([queryNetIOHuff, queryNetIOPuff]).then((res) => {
      commit('getNetMBps', values);
    }).catch((err) => {
      console.log(err);
    });
  },
  getDiskBPS({ commit }, payload) {
    const urlDiskBPSRead = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=rate(node_disk_reads_completed_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',device=~"[a-z]*[a-z]"}[5m]) or sum(rate(windows_logical_disk_read_bytes_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',volume=~"[A-Z]:"}[5m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlDiskBPSWrite = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=rate(node_disk_written_bytes_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',device=~"[a-z]*[a-z]"}[5m]) or sum(rate(windows_logical_disk_write_bytes_total{job="cattle-monitoring-system/paivm-pod-monitor",pod='${ payload.podName }',volume=~"[A-Z]:"}[5m]))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;

    const values = [];
    const queryDiskBPSRead = new Promise((resolve, reject) => {
      this.$axios.get(urlDiskBPSRead).then((dataRead) => {
        if (dataRead && dataRead?.status === 200) {
          dataRead.data?.data?.result.map((result) => {
            const BPSRead = { node: '磁盘读取' };
            const BPSReadDate = [];
            const BPSReadData = [];

            result.values.map((i) => {
              const now = new Date(i[0] * 1000);

              BPSReadDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              BPSReadData.push((parseFloat(i[1]) / 1024).toFixed(2));
            });
            BPSRead.nodeValuesDate = BPSReadDate;
            BPSRead.nodeValuesData = BPSReadData;
            values.push(BPSRead);
          });
        }
      });
      resolve(values);
    });
    const queryDiskBPSWrite = new Promise((resolve, reject) => {
      this.$axios.get(urlDiskBPSWrite).then((dataWrite) => {
        if (dataWrite && dataWrite?.status === 200) {
          dataWrite.data?.data?.result.map((result) => {
            const BPSWrite = { node: '磁盘写入' };
            const BPSWriteDate = [];
            const BPSWriteData = [];

            result.values.map((i) => {
              const now = new Date(i[0] * 1000);

              BPSWriteDate.push(`${ [now.getMonth() + 1, now.getDate()].join('/') }  ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              BPSWriteData.push((parseFloat(i[1]) / 1024).toFixed(2));
            });
            BPSWrite.nodeValuesDate = BPSWriteDate;
            BPSWrite.nodeValuesData = BPSWriteData;
            values.push(BPSWrite);
          });
        }
      });
      resolve(values);
    });

    return Promise.all([queryDiskBPSRead, queryDiskBPSWrite]).then((res) => {
      commit('getDiskBPS', values);
    }).catch((err) => {
      console.log(err);
    });
  },
  getNetLinePlatForm({ commit }, payload) {
    const urlHuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=sort_desc(sum(rate(container_network_receive_bytes_total[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlPuff = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=sort_desc(sum(rate(container_network_transmit_bytes_total[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const values = [];
    const queryHuff = new Promise((resolve, reject) => {
      this.$axios.get(urlHuff)
        .then((netHuffLine) => {
          if (netHuffLine && netHuffLine?.status === 200) {
            netHuffLine.data?.data?.result.map((result) => {
              const netEnter = { node: '网络流入总量' };
              const netHuffDate = [];
              const netHuffData = [];

              result?.values?.map((i) => {
                const now = new Date(i[0] * 1000);

                netHuffDate.push(`${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                netHuffData.push((parseFloat((i[1] / 1024 / 1024) )).toFixed(2));
              });
              netEnter.nodeValuesDate = netHuffDate;
              netEnter.nodeValuesData = netHuffData;
              values.push(netEnter);
            });
          }
          resolve(values);
        }).catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    const queryPuff = new Promise((resolve, reject) => {
      this.$axios.get(urlPuff).then((netPuffLine) => {
        if (netPuffLine && netPuffLine?.status === 200) {
          netPuffLine.data?.data?.result.map((result) => {
            const netOut = { node: '网络流出总量' };
            const netPuffDate = [];
            const netPuffData = [];

            result?.values?.map((i) => {
              const now = new Date(i[0] * 1000);

              netPuffDate.push(`${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              netPuffData.push((parseFloat((i[1] / 1024 / 1024) )).toFixed(2));
            });
            netOut.nodeValuesDate = netPuffDate;
            netOut.nodeValuesData = netPuffData;
            values.push(netOut);
          });
        }
        resolve(values);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });

    return Promise.all([queryHuff, queryPuff]).then((res) => {
      commit('getNetLinePlatForm', values);
    }).catch((err) => {
      if (err.response.status === 404) {
        commit('getNetLinePlatForm', err.response.status);
      } else {
        commit('getNetLinePlatForm', []);
      }
    });
  },
  getDiskIOPSPlatForm({ commit }, payload) {
    const urlRead = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil(sum(rate(container_fs_reads_total[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const urlWrite = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil(sum(rate(container_fs_writes_total[3m])))&start=${ payload.startTime }&end=${ payload.endTime }&step=${ payload.step }`;
    const values = [];
    const queryRead = new Promise((resolve, reject) => {
      this.$axios.get(urlRead)
        .then((dataRead) => {
          if (dataRead && dataRead?.status === 200) {
            dataRead.data?.data?.result.map((result) => {
              const IOPSRead = { node: '磁盘读取' };
              const IOPSReadDate = [];
              const IOPSReadData = [];

              result.values.map((i) => {
                const now = new Date(i[0] * 1000);

                IOPSReadDate.push(`${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
                IOPSReadData.push((parseFloat(i[1])).toFixed(2));
              });
              IOPSRead.nodeValuesDate = IOPSReadDate;
              IOPSRead.nodeValuesData = IOPSReadData;
              values.push(IOPSRead);
            });
          }
          resolve(values);
        }).catch((err) => {
          console.log(err);
          reject(err);
        });
    });
    const queryWrite = new Promise((resolve, reject) => {
      this.$axios.get(urlWrite).then((dataWrite) => {
        if (dataWrite && dataWrite?.status === 200) {
          dataWrite?.data?.data?.result.map((result) => {
            const IOPSWrite = { node: '磁盘写入' };
            const IOPSWriteDate = [];
            const IOPSWriteData = [];

            result.values.map((i) => {
              const now = new Date(i[0] * 1000);

              IOPSWriteDate.push(`${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`);
              IOPSWriteData.push((parseFloat(i[1])).toFixed(2));
            });
            IOPSWrite.nodeValuesDate = IOPSWriteDate;
            IOPSWrite.nodeValuesData = IOPSWriteData;
            values.push(IOPSWrite);
          });
        }
        resolve(values);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });

    return Promise.all([queryRead, queryWrite]).then((res) => {
      commit('getDiskIOPSPlatForm', values);
    }).catch((err) => {
      if (err.response.status === 404) {
        commit('getDiskIOPSPlatForm', err.response.status);
      } else {
        commit('getDiskIOPSPlatForm', []);
      }
    });
  },
  async onLogout({ commit }) {

  },
};
const paiFactory = () => {
  return {
    state,
    getters:   { ...getters },
    mutations: { ...mutations },
    actions:   { ...actions }
  };
};
const config = {
  namespace:      `${ PRODUCT_NAME }-grafana`,
  isClusterStore: false
};

export default {
  specifics: paiFactory(),
  config
};
