<script>
import jsyaml from 'js-yaml';
import merge from 'lodash/merge';
import { DIFF } from '@shell/store/prefs';
import { mapGetters } from 'vuex';
import {
  CATALOG, MANAGEMENT, DEFAULT_WORKSPACE, CAPI, POD
} from '@shell/config/types';
import {
  CHART,
  FROM_CLUSTER,
  FROM_TOOLS,
  NAMESPACE,
  REPO,
  REPO_TYPE,
  VERSION,
  _FLAGGED,
  NAME,
  DESCRIPTION as DESCRIPTION_QUERY,
} from '@shell/config/query-params';
import { CATALOG as CATALOG_ANNOTATIONS, NORMAN_NAME, PROJECT } from '@shell/config/labels-annotations';

import { exceptionToErrorsArray } from '@shell/utils/error';
import { clone, diff, get, set } from '@shell/utils/object';
import { saferDump } from '@shell/utils/create-yaml';
import { WINDOWS } from '@shell/store/catalog';
import { FORCE_VALUES } from '~/pkg/pai/config/settings';
import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import { POD_LABELS } from '~/pkg/pai/config/labels-annotations';
import { PAI_CATALOG } from '~/pkg/pai/config/types';
import ChildHook, { AFTER_SAVE_HOOKS, BEFORE_SAVE_HOOKS } from '@shell/mixins/child-hook';

const VALUES_STATE = {
  FORM: 'FORM',
  YAML: 'YAML',
  DIFF: 'DIFF'
};

export default {
  mixins: [ChildHook],
  data() {
    /* Helm CLI options that are not persisted on the back end,
    but are used for the final install/upgrade operation. */
    const defaultCmdOpts = {
      cleanupOnFail: false,
      crds:          true,
      hooks:         true,
      force:         false,
      resetValues:   false,
      openApi:       true,
      wait:          true,
      timeout:       600,
      historyMax:    5,
    };

    return {
      defaultRegistrySetting: '',
      customRegistrySetting:  '',
      serverUrlSetting:       null,
      chartValues:            null,
      clusterRegistry:        '',
      originalYamlValues:     null,
      errors:                 null,
      existing:               null,
      globalRegistry:         '',
      forceNamespace:         null,
      loadedVersion:          null,
      loadedVersionValues:    null,
      value:                  null,
      valuesComponent:        null,
      valuesYaml:             '',
      project:                null,
      migratedApp:            false,
      defaultCmdOpts,
      customCmdOpts:          { ...defaultCmdOpts },

      nameDisabled:            true,
      formYamlOption:          VALUES_STATE.YAML,
      showCommandStep:         false,
      showCustomRegistryInput: false,
    };
  },

  computed: {
    ...mapGetters({ inStore: 'catalog/inStore' }),
    ...mapGetters(['isRancher']),
    showingYaml() {
      return this.formYamlOption === VALUES_STATE.YAML || ( !this.valuesComponent && !this.hasQuestions );
    },

    stepperName() {
      return this.existing?.nameDisplay || this.chart?.chartNameDisplay;
    },

    cmdOptions() {
      return this.showCommandStep ? this.customCmdOpts : this.defaultCmdOpts;
    },
    showCustomRegistry() {
      const global = this.versionInfo?.values?.global || {};

      return global.systemDefaultRegistry !== undefined || global.cattle?.systemDefaultRegistry !== undefined;
    },
    targetNamespace() {
      if ( this.forceNamespace ) {
        return this.forceNamespace;
      } else if ( this.value?.metadata.namespace ) {
        return this.value.metadata.namespace;
      }

      return 'default';
    },
    query() {
      const query = this.$route.query;

      return {
        repoType:     query[REPO_TYPE],
        repoName:     query[REPO],
        chartName:    query[CHART],
        versionName:  query[VERSION],
        appNamespace: query[NAMESPACE] || '',
        appName:      query[NAME] || '',
        description:  query[DESCRIPTION_QUERY]
      };
    },
  },
  watch: {
    value: {
      handler(nue) {
        if (nue && this.chart) {
          this.$set(this.value.metadata, 'name', this.chart.chartName);
        }
      },
      deep:      true,
      immediate: true,
    },
  },
  methods: {
    async fetchData() {
      this.serverUrlSetting = await this.$store.dispatch('management/find', {
        type: MANAGEMENT.SETTING,
        id:   'server-url'
      });
      if (this.showCustomRegistry) {
        // Note: Cluster scoped registry is only supported for node driver clusters
        this.clusterRegistry = await this.getClusterRegistry();
        this.globalRegistry = await this.getGlobalRegistry();
        this.defaultRegistrySetting = this.clusterRegistry || this.globalRegistry;
      }
      if ( this.existing ) {
        /*
        If the Helm chart is already installed,
        use the existing namespace by default.
      */

        this.forceNamespace = this.existing.metadata.namespace;
        this.nameDisabled = true;
      } else if (this.$route.query[FROM_CLUSTER] === _FLAGGED) {
        /* For Fleet, use the fleet-default namespace. */
        this.forceNamespace = DEFAULT_WORKSPACE;
      } else if ( this.chart?.targetNamespace ) {
        /* If a target namespace is defined in the chart,
        set the target namespace as default. */
        this.forceNamespace = this.chart.targetNamespace;
      } else if ( this.query.appNamespace ) {
        /* If a namespace is defined in the URL query,
         use that namespace as default. */
        this.forceNamespace = this.query.appNamespace;
      } else if (FORCE_VALUES[this.chart.chartName]) {
        this.nameDisabled = true;
        this.forceNamespace = FORCE_VALUES[this.chart.chartName].FORCE_NAMESPACE;
      } else {
        this.forceNamespace = null;
      }

      if (this.forceNamespace && !this.existing) {
        let ns;

        /*
          Before moving forward, check to make sure the
          default namespace exists and the logged-in user
          has permission to see it.
        */
        try {
          ns = await this.$store.dispatch('cluster/find', { type: NAMESPACE, id: this.forceNamespace });
          const project = ns.metadata.annotations?.[PROJECT];

          if (project) {
            this.project = project.replace(':', '/');
          }
        } catch {}
      }
      if ( !this.loadedVersion || this.loadedVersion !== this.version.key ) {
        let userValues;

        if ( this.loadedVersion ) {
          if ( this.showingYaml ) {
            this.applyYamlToValues();
          }
          userValues = diff(this.loadedVersionValues, this.chartValues);
        } else if ( this.existing ) {
          /* For an already installed app, use the values from the previous install. */
          userValues = clone(this.existing.spec?.values || {});
        } else {
          /* For an new app, start empty. */
          userValues = {};
        }

        /*
          Remove global values if they are identical to
          the currently available information about the cluster
          and Rancher settings.

          Immediately before the Helm chart is installed or
          upgraded, the global values are re-added.
        */
        this.removeGlobalValuesFrom(userValues);

        /*
          The merge() method is used to merge two or more objects
          starting with the left-most to the right-most to create a
          parent mapping object. When two keys are the same, the
          generated object will have value for the rightmost key.
          In this case, any values in userValues override any
          matching values in versionInfo.
        */
        this.chartValues = merge(merge({}, this.versionInfo?.values || {}), userValues);
        if (this.showCustomRegistry) {
          /**
           * The input to configure the registry should never be
           * shown for third-party charts, which don't have Rancher
           * global values.
           */
          const existingRegistry = this.chartValues?.global?.systemDefaultRegistry || this.chartValues?.global?.cattle?.systemDefaultRegistry;

          delete this.chartValues?.global?.systemDefaultRegistry;
          delete this.chartValues?.global?.cattle?.systemDefaultRegistry;

          this.customRegistrySetting = existingRegistry || this.defaultRegistrySetting;
          this.showCustomRegistryInput = !!this.customRegistrySetting;
        }

        /* Serializes an object as a YAML document */
        this.valuesYaml = saferDump(this.chartValues);

        /* For YAML diff */
        if ( !this.loadedVersion ) {
          this.originalYamlValues = this.valuesYaml;
        }

        this.loadedVersionValues = this.versionInfo?.values || {};
        this.loadedVersion = this.version?.key;
      }
      this.value = await this.$store.dispatch('cluster/create', {
        type:     'chartInstallAction',
        metadata: {
          namespace: this.forceNamespace || this.$store.getters['defaultNamespace'],
          name:      this.existing?.spec?.name || this.query.appName || '',
        }
      });
      if ( !this.existing) {
        /*
          The target name is used for Git repos for Fleet.
          The target name indicates the name of the cluster
          group that the chart is meant to be installed in.
        */
        if ( this.chart?.targetName ) {
          /*
            Set the name of the chartInstallAction
            to the name of the cluster group
            where the chart should be installed.
          */
          this.value.metadata.name = this.chart.targetName;
          this.nameDisabled = true;
        } else if ( this.query.appName ) {
          this.value.metadata.name = this.query.appName;
        } else if (FORCE_VALUES[this.chart.chartName]) {
          this.value.metadata.name = FORCE_VALUES[this.chart.chartName].FORCE_NAME;
          this.nameDisabled = true;
        } else {
          this.nameDisabled = false;
        }

        if ( this.query.description ) {
          this.customCmdOpts.description = this.query.description;
        }
      }
    },
    async getClusterRegistry() {
      const hasPermissionToSeeProvCluster = this.$store.getters[`management/schemaFor`](CAPI.RANCHER_CLUSTER);

      if (hasPermissionToSeeProvCluster) {
        const mgmCluster = this.$store.getters['currentCluster'];
        const provCluster = mgmCluster?.provClusterId ? await this.$store.dispatch('management/find', {
          type: CAPI.RANCHER_CLUSTER,
          id:   mgmCluster.provClusterId
        }) : {};

        if (provCluster.isRke2) { // isRke2 returns true for both RKE2 and K3s clusters.
          const agentConfig = provCluster.spec.rkeConfig.machineSelectorConfig.find(x => !x.machineLabelSelector).config;

          // If a cluster scoped registry exists,
          // it should be used by default.
          const clusterRegistry = agentConfig?.['system-default-registry'] || '';

          if (clusterRegistry) {
            return clusterRegistry;
          }
        }
        if (provCluster.isRke1) {
          // For RKE1 clusters, the cluster scoped private registry is on the management
          // cluster, not the provisioning cluster.
          const rke1Registries = mgmCluster.spec.rancherKubernetesEngineConfig.privateRegistries;

          if (rke1Registries?.length > 0) {
            const defaultRegistry = rke1Registries.find((registry) => {
              return registry.isDefault;
            });

            return defaultRegistry.url;
          }
        }
      }
    },

    async getGlobalRegistry() {
      // Use the global registry as a fallback.
      // If it is an empty string, the container
      // runtime will pull images from docker.io.
      const globalRegistry = await this.$store.dispatch('management/find', {
        type: MANAGEMENT.SETTING,
        id:   'system-default-registry'
      });

      return globalRegistry.value;
    },

    async doneMini() {
      // 设置别名
      try {
        const app = await this.$store.dispatch('cluster/find', {
          type: PAI_CATALOG.HELM_CHART,
          id:   `${ this.targetNamespace }/${ this.chart.chartName }`,
          opt:  { watch: true },
        });

        if (app) {
          app.setAnnotation(NORMAN_NAME, this.chart.chartNameDisplay);
          app.save();
        }
      } catch (e) {
        console.log(e);
      }

      const pods = await this.$store.dispatch('cluster/findAll', { type: POD });
      const jobName = `helm-install-${ this.value.metadata.name }`;
      const pod = pods.find(v => v.labels && v.labels[POD_LABELS.JOB_NAME] === jobName);

      if (pod) {
        const podLocation = {
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            product:   this.$store.getters['productId'],
            cluster:   this.$store.getters['clusterId'],
            resource:  POD,
            namespace: this.value.metadata.namespace,
            id:        pod.name,
          }
        };

        await this.$router.replace(podLocation);
        setTimeout(() => {
          pod.openLogs();
        }, 3000);
      } else {
        const detailLocation = {
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            product:   PAI,
            cluster:   this.$store.getters['clusterId'],
            resource:  PAI_CATALOG.HELM_CHART,
            namespace: this.value.metadata.namespace,
            id:        this.value.name,
          },
        };

        await this.$router.replace(detailLocation);
      }
    },
    async done() {
      try {
        const apps = await this.$store.dispatch('cluster/findAll', {
          type: CATALOG.APP,
          opt:  { force: true },
        });
        // catalog.cattle.io.app的名称生成后可能有后缀,需要查找命名空间和名称。
        const app = apps.find(v => v.metadata.namespace === this.value.metadata.namespace && v.metadata.name.includes(this.value.metadata.name));

        if (app) {
          app.setAnnotation(NORMAN_NAME, this.stepperName);
          app.save();
        }
      } catch (e) {
        console.log(e);
      }
      if (this.$route.query[FROM_TOOLS] === _FLAGGED) {
        this.$router.replace(this.clusterToolsLocation());
      } else if (this.$route.query[FROM_CLUSTER] === _FLAGGED) {
        this.$router.replace(this.clustersLocation());
      } else {
        // If the create app process fails helm validation then we still get here... so until this is fixed new apps will be taken to the
        // generic apps list (existing apps will be taken to their detail page)
        this.$router.replace(this.appLocation());
      }
    },
    appLocation() {
      return {
        name:   `${ PAI }-c-cluster-resource`,
        params: {
          product:  this.$store.getters['productId'],
          cluster:  this.$store.getters['clusterId'],
          resource: PAI_CATALOG.APP,
        }
      };
    },
    async finish() {
      try {
        await this.fetchData();
        const isUpgrade = !!this.existing;

        this.errors = [];
        await this.applyHooks(BEFORE_SAVE_HOOKS);

        const { errors, input } = this.actionInput(isUpgrade);

        if ( errors?.length ) {
          this.errors = errors;

          return;
        }

        const res = await this.repo.doAction(('install'), input);
        if (res.success) {
          await this.applyHooks(AFTER_SAVE_HOOKS);
          if (!this.isRancher) {
            if (res?.code === 200) {
              await this.doneMini();
            }
          }
        }
        if (this.isRancher) {
          await this.done();
        }
      } catch (err) {
        this.errors = exceptionToErrorsArray(err);
      }
    },

    addGlobalValuesTo(values) {
      let global = values.global;

      if ( !global ) {
        global = {};
        set(values, 'global', global);
      }

      let cattle = global.cattle;

      if ( !cattle ) {
        cattle = {};
        set(values.global, 'cattle', cattle);
      }

      const cluster = this.currentCluster;
      const projects = this.$store.getters['management/all'](MANAGEMENT.PROJECT);
      const systemProjectId = projects.find(p => p.spec?.displayName === 'System')?.id?.split('/')?.[1] || '';

      const serverUrl = this.serverUrlSetting?.value || '';
      const isWindows = (cluster?.workerOSs || []).includes(WINDOWS);
      const pathPrefix = cluster?.spec?.rancherKubernetesEngineConfig?.prefixPath || '';
      const windowsPathPrefix = cluster?.spec?.rancherKubernetesEngineConfig?.winPrefixPath || '';

      setIfNotSet(cattle, 'clusterId', cluster?.id);
      setIfNotSet(cattle, 'clusterName', cluster?.nameDisplay);

      if (this.showCustomRegistry) {
        set(cattle, 'systemDefaultRegistry', this.customRegistrySetting);
        set(global, 'systemDefaultRegistry', this.customRegistrySetting);
      }

      setIfNotSet(global, 'cattle.systemProjectId', systemProjectId);
      setIfNotSet(cattle, 'url', serverUrl);
      setIfNotSet(cattle, 'rkePathPrefix', pathPrefix);
      setIfNotSet(cattle, 'rkeWindowsPathPrefix', windowsPathPrefix);

      if ( isWindows ) {
        setIfNotSet(cattle, 'windows.enabled', true);
      }

      return values;

      function setIfNotSet(obj, key, val) {
        if ( typeof get(obj, key) === 'undefined' ) {
          set(obj, key, val);
        }
      }
    },

    removeGlobalValuesFrom(values) {
      if ( !values ) {
        return;
      }

      const cluster = this.$store.getters['currentCluster'];
      const serverUrl = this.serverUrlSetting?.value || '';
      const isWindows = (cluster?.workerOSs || []).includes(WINDOWS);
      const pathPrefix = cluster?.spec?.rancherKubernetesEngineConfig?.prefixPath || '';
      const windowsPathPrefix = cluster?.spec?.rancherKubernetesEngineConfig?.winPrefixPath || '';

      if ( values.global?.cattle ) {
        deleteIfEqual(values.global.cattle, 'clusterId', cluster?.id);
        deleteIfEqual(values.global.cattle, 'clusterName', cluster?.nameDisplay);
        deleteIfEqual(values.global.cattle, 'url', serverUrl);
        deleteIfEqual(values.global.cattle, 'rkePathPrefix', pathPrefix);
        deleteIfEqual(values.global.cattle, 'rkeWindowsPathPrefix', windowsPathPrefix);

        if ( isWindows ) {
          deleteIfEqual(values.global.cattle.windows, 'enabled', true);
        }
      }

      if ( values.global?.cattle?.windows && !Object.keys(values.global.cattle.windows).length ) {
        delete values.global.cattle.windows;
      }

      if ( values.global?.cattle && !Object.keys(values.global.cattle).length ) {
        delete values.global.cattle;
      }

      if ( !Object.keys(values.global || {}).length ) {
        delete values.global;
      }

      return values;

      function deleteIfEqual(obj, key, val) {
        if ( get(obj, key) === val ) {
          delete obj[key];
        }
      }
    },

    applyYamlToValues() {
      try {
        this.chartValues = jsyaml.load(this.valuesYaml);
      } catch (err) {
        return { errors: exceptionToErrorsArray(err) };
      }

      return { errors: [] };
    },

    /*
      actionInput determines what values Rancher finally sends
      to the backend when installing or upgrading the app. It
      injects Rancher-specific values into the chart values.
    */
    actionInput(isUpgrade) {
      /* Default values defined in the Helm chart itself */
      const fromChart = this.versionInfo?.values || {};

      const errors = [];

      if ( this.showingYaml || this.showingYamlDiff ) {
        const { errors: yamlErrors } = this.applyYamlToValues();

        errors.push(...yamlErrors);
      }

      /*
        Only save the values that differ from the chart's standard values.yaml.
        chartValues is created by applying the user's customized onto
        the default chart values.
      */
      const values = diff(fromChart, this.chartValues);

      /*
        Refer to the developer docs at docs/developer/helm-chart-apps.md
        for details on what values are injected and where they come from.
      */
      this.addGlobalValuesTo(values);

      const form = JSON.parse(JSON.stringify(this.value));

      /*
        Migrated annotations are required to allow a deprecated legacy app to be
        upgraded.
      */
      const migratedAnnotations = this.migratedApp ? { [CATALOG_ANNOTATIONS.MIGRATED]: 'true' } : {};

      if (this.chart.chartName === 'rancher-monitoring') {
        set(values, 'rancher-monitoring.global.cattle.clusterId', 'local');
        set(values, 'rancher-monitoring.global.cattle.clusterName', 'local');
      }

      const chart = {
        chartName:   this.chart.chartName,
        version:     this.version?.version || this.query.versionName,
        releaseName: form.metadata.name,
        description: this.customCmdOpts.description,
        annotations: {
          ...migratedAnnotations,
          [CATALOG_ANNOTATIONS.SOURCE_REPO_TYPE]: this.chart.repoType,
          [CATALOG_ANNOTATIONS.SOURCE_REPO_NAME]: this.chart.repoName
        },
        values,
      };

      if ( isUpgrade ) {
        chart.resetValues = this.cmdOptions.resetValues;
      }

      /*
        Configure Helm CLI options for doing the install or
        upgrade operation.
      */
      const out = {
        charts:    [chart],
        noHooks:   this.cmdOptions.hooks === false,
        timeout:   this.cmdOptions.timeout > 0 ? `${ this.cmdOptions.timeout }s` : null,
        wait:      this.cmdOptions.wait === true,
        namespace: form.metadata.namespace,
        projectId: this.project,
      };

      /*
        Configure Helm CLI options that are specific to
        installs or specific to upgrades.
      */
      if ( isUpgrade ) {
        out.force = this.cmdOptions.force === true;
        out.historyMax = this.cmdOptions.historyMax;
        out.cleanupOnFail = this.cmdOptions.cleanupOnFail;
      } else {
        out.disableOpenAPIValidation = this.cmdOptions.openApi === false;
        out.skipCRDs = this.cmdOptions.crds === false;
      }

      const more = [];

      /*
        An example value for auto is ["rancher-monitoring-crd=match"].
        It is an array of chart names that lets Rancher know of other
        charts that should be auto-installed at the same time.
      */
      let auto = (this.version?.annotations?.[CATALOG_ANNOTATIONS.AUTO_INSTALL] || '').split(/\s*,\s*/).filter(x => !!x).reverse();

      for ( const constraint of auto ) {
        const provider = this.$store.getters['catalog/versionSatisfying']({
          constraint,
          repoName:     this.chart.repoName,
          repoType:     this.chart.repoType,
          chartVersion: this.version.version,
        });

        if ( provider ) {
          more.push(provider);
        } else {
          errors.push(`This chart requires ${ constraint } but no matching chart was found`);
        }
      }

      auto = (this.version?.annotations?.[CATALOG_ANNOTATIONS.AUTO_INSTALL_GVK] || '').split(/\s*,\s*/).filter(x => !!x).reverse();

      for ( const gvr of auto ) {
        const provider = this.$store.getters['catalog/versionProviding']({
          gvr,
          repoName: this.chart.repoName,
          repoType: this.chart.repoType
        });

        if ( provider ) {
          more.push(provider);
        } else {
          errors.push(`This chart requires another chart that provides ${ gvr }, but none was was found`);
        }
      }

      /*
        'more' contains the values for the CRD chart, which needs the same
        global and cattle values as the chart. It could also contain additional
        charts that may not be CRD charts but are also meant to be installed at
        the same time.
      */
      for ( const dependency of more ) {
        out.charts.unshift({
          chartName:   dependency.name,
          version:     dependency.version,
          releaseName: dependency.annotations[CATALOG_ANNOTATIONS.RELEASE_NAME] || dependency.name,
          projectId:   this.project,
          values:      this.addGlobalValuesTo({ global: values.global }),
          annotations: {
            ...migratedAnnotations,
            [CATALOG_ANNOTATIONS.SOURCE_REPO_TYPE]: dependency.repoType,
            [CATALOG_ANNOTATIONS.SOURCE_REPO_NAME]: dependency.repoName
          },
        });
      }

      return { errors, input: out };
    },

  },
};
</script>
