<script>
import { filterHiddenLocalCluster, filterOnlyKubernetesClusters } from '@/shell/utils/cluster';
import ClusterList from './components/ClusterList';
import ClusterChart from './components/ClusterChart';
import { AGE, STATE } from '@shell/config/table-headers';
import { createMemoryFormat, formatSi, parseSi } from '@shell/utils/units';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { MANAGEMENT, CAPI, NAMESPACE } from '@shell/config/types';
import PromptRemove from '@shell/components/PromptRemove';
import { compare } from '@shell/utils/sort';
import { CLUSTER_ANNOTATIONS } from '../../config/labels-annotations';
import PromptModal from '@shell/components/PromptModal.vue';

export default {
  layout:     'pai/home',
  components: {
    PromptModal,
    ClusterList, PromptRemove, ClusterChart,
  },
  fetch() {
    this.$store.dispatch('management/findAll', { type: CAPI.RANCHER_CLUSTER });
    this.$store.dispatch('management/findAll', { type: MANAGEMENT.CLUSTER });
    this.namespaceRows = this.$store.getters['management/all'](NAMESPACE);
  },
  data() {
    return {
      PAI,
      resource:      CAPI.RANCHER_CLUSTER,
      schema:        this.$store.getters['management/schemaFor'](CAPI.RANCHER_CLUSTER),
      namespaceRows: [],
    };
  },
  computed: {
    provClusters() {
      return this.$store.getters['management/all'](CAPI.RANCHER_CLUSTER);
    },
    clusters() {
      const clusters = this.$store.getters['management/all'](MANAGEMENT.CLUSTER);

      const filteredClusters = filterHiddenLocalCluster(filterOnlyKubernetesClusters(clusters || []), this.$store);

      filteredClusters.sort((a, b) => {
        return compare(a.labels[CLUSTER_ANNOTATIONS.COLLECTION], b.labels[CLUSTER_ANNOTATIONS.COLLECTION]);
      }).reverse();

      return filteredClusters;
    },
    kubeClusters() {
      const clusters = filterHiddenLocalCluster(filterOnlyKubernetesClusters(this.provClusters || []), this.$store);

      clusters.sort((a, b) => {
        return compare(a.labels[CLUSTER_ANNOTATIONS.COLLECTION], b.labels[CLUSTER_ANNOTATIONS.COLLECTION]);
      }).reverse();

      return clusters;
    },
    clusterHeaders() {
      return [
        STATE,
        {
          name:      'nameDisplay',
          label:     this.t('tableHeaders.name'),
          value:     'mgmt.nameDisplay',
          sort:      ['nameSort'],
          formatter: 'PaiClusterName'
        },
        {
          label:     this.t('landing.clusters.provider'),
          value:     'provisioner',
          name:      'Provider',
          sort:      ['provisioner'],
          formatter: 'PaiClusterProvider'
        },
        {
          label: this.t('landing.clusters.kubernetesVersion'),
          value: 'kubernetesVersion',
          name:  'Kubernetes Version'
        },
        {
          label: this.t('tableHeaders.cpu'),
          value: '',
          name:  'cpu',
          sort:  ['status.allocatable.cpu', 'status.available.cpu']
        },
        {
          label: this.t('tableHeaders.memory'),
          value: '',
          name:  'memory',
          sort:  ['status.allocatable.memory', 'status.available.memory']
        },
        {
          label: this.t('pai.overview.node'),
          value: 'status.nodeCount',
          name:  'node',
          sort:  ['status.nodeCount']
        },
        {
          label:        this.t('tableHeaders.pods'),
          name:         'pods',
          value:        '',
          sort:         ['status.allocatable.pods', 'status.available.pods'],
          formatter:    'PaiPodsUsage',
          delayLoading: true
        },
        AGE,
      ];
    },
  },
  methods: {
    cpuAllocatable(cluster) {
      return parseSi(cluster.mgmt.status.allocatable?.cpu);
    },
    memoryAllocatable(cluster) {
      const parsedAllocatable = (parseSi(cluster.mgmt.status.allocatable?.memory) || 0).toString();
      const format = createMemoryFormat(parsedAllocatable);

      return formatSi(parsedAllocatable, format);
    },
    nodeCount(cluster) {
      return cluster?.mgmt?.status?.nodeCount ? cluster?.mgmt?.status?.nodeCount : '-';
    }
  },
};
</script>
<template>
  <div class="overview">
    <div class="cluster-chart">
      <ClusterChart
        :rows="clusters"
      />
    </div>
    <div class="cluster-tabs">
      <el-tabs value="cluster">
        <el-tab-pane
          :label="`${t('pai.overview.cluster')} (${kubeClusters.length})`"
          name="cluster"
        >
          <ClusterList
            key-field="id"
            :rows="kubeClusters"
            :headers="clusterHeaders"
            :loading="!kubeClusters"
          >
            <template #col:name="{row}">
              <td>
                <div class="list-cluster-name">
                  <span v-if="row.isReady&& row.state==='active'">
                    <n-link
                      :to="{ name: 'pai-c-cluster-overview', params: { cluster: row.id, product: PAI }}"
                    >
                      {{ row.nameDisplay }}
                    </n-link>
                  </span>
                  <span v-else>{{ row.nameDisplay }}</span>
                  <i
                    v-if="row.unavailableMachines"
                    v-tooltip="row.unavailableMachines"
                    class="conditions-alert-icon icon-alert icon"
                  />
                </div>
              </td>
            </template>
            <template #col:cpu="{row}">
              <td v-if="cpuAllocatable(row)">
                {{ `${cpuAllocatable(row)} ${t('landing.clusters.cores', {count:cpuAllocatable(row) })}` }}
              </td>
              <td v-else>
                -
              </td>
            </template>
            <template #col:memory="{row}">
              <td v-if=" memoryAllocatable(row) && !memoryAllocatable(row).match(/^0 [a-zA-z]/)">
                {{ memoryAllocatable(row) }}
              </td>
              <td v-else>
                -
              </td>
            </template>
            <template #col:node="{row}">
              <td>{{ nodeCount(row) }}</td>
            </template>
          </ClusterList>
        </el-tab-pane>
      </el-tabs>
    </div>
    <PromptRemove />
    <PromptModal />
  </div>
</template>
<style lang="scss" scoped>
.overview{
  .cluster-tabs{
    padding: 10px 10px 0 20px;
  }
  .cluster-chart{
    padding: 10px 0px 10px 0px;
    background-color: #f5f4f6;
  }
}
</style>
