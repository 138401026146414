export function omitString(str, num = 10) {
  if (!str || typeof str !== 'string') {
    return '';
  }
  if (str.length > num) {
    return `${ str.slice(0, num) }...`;
  }

  return str;
}

/* 将字符串类型的范围转换为数组 */
export function rangeToArr(str) {
  if (str) {
    const result = [];

    str.split(',')?.forEach((v) => {
      if (v.includes('-')) {
        const min = Number(v.split('-')[0]);
        const max = Number(v.split('-')[1]);

        if (!isNaN(min) && !isNaN(max)) {
          for (let i = min;i < max + 1;i++) {
            result.push(i);
          }
        }
      } else {
        const item = Number(v);

        if (!isNaN(item)) {
          result.push(item);
        }
      }
    });

    return result;
  } else {
    return [];
  }
}

/* 多个内核参数表达式组成的字符串转换为数组 */
export function kparamsToArr(str) {
  if (!str || typeof str !== 'string') {
    return [];
  }

  return str.split(' ');
}
