<template>
  <div class="cluster-single-module bgCard cluster-flex">
    <!--云主机组-->
    <div
      v-if="vmSchema"
      class="module-main"
    >
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.vmset') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ cloudData.active }}</span>/<span class="box-numMini">{{ cloudData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.On') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--容器组-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.pod') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ podData.active }}</span>/<span class="box-numMini">{{ podData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--有状态负载-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.apps.statefulLoad') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ statefulsetData.active }}</span>/<span class="box-numMini">{{ statefulsetData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--无状态负载-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.apps.statelessLoad') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ deploymentData.active }}</span>/<span class="box-numMini">{{ deploymentData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--普通任务-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.batch.job') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ jobData.active }}</span>/<span class="box-numMini">{{ jobData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--定时任务-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.batch.cronjob') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ cronjobData.active }}</span>/<span class="box-numMini">{{ cronjobData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
    <!--守护进程集-->
    <div class="module-main">
      <div class="module-main-top">
        <span class="img-bg">
          <img src="../images/module-icon.png">
        </span>
        <span
          class="box-title"
          style="margin-left: 6px"
        >
          {{ t('pai.menu.apps.daemonset') }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ daemonsetData.active }}</span>/<span class="box-numMini">{{ daemonsetData.all }}</span>
          </div>
          <div class="box-text">
            {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PAI_POD, PAI_RESOURCES, PAI_WORKLOAD_TYPES } from '../../../../../config/types';
import { PRODUCT_NAME } from '../../../../../config/pai';

export default {
  name:  'ClusterSingleModule',
  props: {
    cloudData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    podData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    statefulsetData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    deploymentData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    jobData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    cronjobData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    daemonsetData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    }
  },
  computed: {
    vmSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VMSET);
    }
  },
  data() {
    return {};
  },
  methods: {
    handleRoute(val) {
      let resource = '';

      switch (val) {
      case 'cloud':
        resource = PAI_RESOURCES.VMSET;
        break;
      case 'container':
        resource = PAI_POD;
        break;
      case 'state':
        resource = PAI_WORKLOAD_TYPES.STATEFUL_SET;
        break;
      case
        'stateNo':
        resource = PAI_WORKLOAD_TYPES.DEPLOYMENT;
        break;
      case 'task':
        resource = PAI_WORKLOAD_TYPES.JOB;
        break;
      case 'taskD':
        resource = PAI_WORKLOAD_TYPES.CRON_JOB;
        break;
      case 'course':
        resource = PAI_WORKLOAD_TYPES.DAEMON_SET;
      }

      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product: PRODUCT_NAME,
          resource,
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cluster-single-module{
  background-color: #FFFFFF;
  .module-main{
    width: 14%;
    cursor: pointer;
    padding: 10px 15px;
    border-right: 1px solid #BBBBBB;
    .module-main-top {
      .img-bg{
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #F0F2F5;
      }
      img{
        width: 16px;
        height: 16px;
      }
      .box-title {
        margin-bottom: 16px;
        font-weight: 600;
        margin-top: 6px;
      }
    }
    .module-main-bottom{
      margin-top: 16px;
      .box-text{
        padding-top: 4px;
      }
      .green-color {
        font-size: 18px;
        font-weight: 600;
        color: #4AC58F;
      }
    }
  }
  .module-main:last-child{
    border-right: 0px;
  }

}
.bgCard {
  padding: 10px;
  margin: 14px 0px;
  background-color: #fff;
  border-radius: 5px;
}
.cluster-flex {
  display: flex;
  justify-content: space-between;
}
</style>
